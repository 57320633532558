export const TalkIcon = ({ ...props }) => {
  return (
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M46.1797 57.5352C46.427 57.432 49.0422 56.575 49.2852 56.4639C50.6322 55.8488 52.1435 55.6187 53.574 56.0014L55.3448 56.4752C58.9396 57.4368 62.2283 54.1486 61.2665 50.5544L60.7927 48.7838C60.4099 47.3535 60.64 45.8424 61.2553 44.4957C62.6516 41.4393 63.4297 38.0413 63.4297 34.4616C63.4297 21.1093 52.6039 10.2852 39.2497 10.2852C29.1973 10.2852 16.1768 18.5352 15.4297 33.5352"
        stroke="url(#paint0_linear_122_3637)"
        strokeWidth="1.3"
      />
      <path
        d="M43.4883 31.5H43.5115M52.4771 31.5H52.5002"
        stroke="url(#paint1_linear_122_3637)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30 66C40.7696 66 49.5 57.2696 49.5 46.5C49.5 35.7304 40.7696 27 30 27C19.2304 27 10.5 35.7304 10.5 46.5C10.5 49.5115 11.1827 52.3636 12.4017 54.91C12.8207 55.7852 12.9678 56.7767 12.717 57.714L11.9174 60.7025C11.2872 63.0579 13.4421 65.2128 15.7975 64.5826L18.786 63.783C19.7233 63.5322 20.7149 63.6793 21.59 64.0983C24.1364 65.3173 26.9885 66 30 66Z"
        stroke="url(#paint2_linear_122_3637)"
        strokeWidth="1.3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.2089 52.339C23.8057 52.339 23.4791 52.012 23.4791 51.6091C23.4791 51.2062 23.8057 50.8793 24.2089 50.8793H31.692C32.0952 50.8793 32.4218 51.2062 32.4218 51.6091C32.4218 52.012 32.0952 52.339 31.6923 52.339H24.2089ZM32.3937 43.5025C33.358 43.5025 34.1399 44.2843 34.1399 45.2487C34.1399 46.213 33.358 46.9945 32.3937 46.9945C31.4293 46.9945 30.6475 46.213 30.6475 45.2487C30.6475 44.2843 31.4293 43.5025 32.3937 43.5025ZM23.5079 43.5025C24.4723 43.5025 25.2538 44.2843 25.2538 45.2487C25.2538 46.213 24.4723 46.9945 23.5079 46.9945C22.5436 46.9945 21.7617 46.213 21.7617 45.2487C21.7617 44.2843 22.5436 43.5025 23.5079 43.5025ZM27.9506 34.5C28.7255 34.5 29.3534 35.1282 29.3534 35.9027C29.3534 36.4157 29.0782 36.8642 28.6674 37.1088C28.6741 37.1485 28.6775 37.1893 28.6775 37.2308V39.2842H36.1963C37.3111 39.2842 38.2181 40.1698 38.2181 41.2586V54.3191C38.2181 55.4079 37.3111 56.2935 36.1963 56.2935H19.6996C18.5848 56.2935 17.6777 55.4079 17.6777 54.3191V41.2586C17.6777 40.1698 18.5848 39.2842 19.6996 39.2842H27.2179V37.2308C27.2179 37.1881 27.2215 37.1463 27.2285 37.1057C26.8207 36.8603 26.5479 36.4135 26.5479 35.9027C26.5479 35.1282 27.1758 34.5 27.9506 34.5ZM19.6996 40.7435C19.3895 40.7435 19.1374 40.9745 19.1374 41.2586V54.3191C19.1374 54.6032 19.3898 54.8341 19.6996 54.8341H36.1963C36.5064 54.8341 36.7584 54.6032 36.7584 54.3191V41.2586C36.7584 40.9745 36.506 40.7435 36.1963 40.7435H19.6996ZM15 51.2536C15 51.6565 15.3266 51.9834 15.7298 51.9834C16.1327 51.9834 16.4593 51.6565 16.4597 51.2536V44.7683C16.4597 44.3654 16.1331 44.0385 15.7298 44.0385C15.3266 44.0385 15 44.3654 15 44.7683V51.2536ZM39.4357 51.2536C39.4357 51.6565 39.7623 51.9834 40.1655 51.9834C40.5688 51.9834 40.8954 51.6565 40.8954 51.2536V44.7683C40.8954 44.3654 40.5688 44.0385 40.1655 44.0385C39.7623 44.0385 39.4357 44.3654 39.4357 44.7683V51.2536Z"
        fill="url(#paint3_linear_122_3637)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_122_3637"
          x1="39.4297"
          y1="10.2852"
          x2="39.4297"
          y2="57.5352"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DD4A34" />
          <stop offset="1" stopColor="#49153F" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_122_3637"
          x1="47.9943"
          y1="31.5"
          x2="47.9943"
          y2="32.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DD4A34" />
          <stop offset="1" stopColor="#49153F" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_122_3637"
          x1="30"
          y1="27"
          x2="30"
          y2="66"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DD4A34" />
          <stop offset="1" stopColor="#49153F" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_122_3637"
          x1="27.9477"
          y1="34.5"
          x2="27.9477"
          y2="56.2935"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C04032" />
          <stop offset="1" stopColor="#451144" />
        </linearGradient>
      </defs>
    </svg>
  );
};
